import React, { useState } from 'react';

import { Form, FormProps, FormSpy } from 'react-final-form';

import { Box, Button, CircularProgress, Typography } from '@material-ui/core';

import { Decorator, Mutator } from 'final-form';

import FormButtons from 'components/forms/FormButtons';

export type FinalFormWizardProps<FormValues extends Record<string, any>> = Omit<
  FormProps<FormValues>,
  'onSubmit' | 'validate'
> & {
  // Current active page
  page: number;

  // Wizard Pages
  children:
    | React.ReactElement<FinalFormWizardPageProps>
    | React.ReactElement<FinalFormWizardPageProps>[];

  // Callback when form step is complete
  onNext: (values: any) => void;

  // Callback when form step is reverted
  onBack: () => void;

  // Callback when form is complete
  onSubmit: (values: any) => Promise<any>;

  // Submit button text on final wizard page
  submitText?: string;

  // Boolean to hide form controls (default: false)
  hideControls?: boolean;

  // Boolean to hide submit errors
  hideSubmitError?: boolean;

  // Form Submit Ref
  submitRef?: React.MutableRefObject<any>;

  // Use the to externally watch the values of the form. You probably DONT need this
  onFormStateChange?: (values: any) => void;

  // Submit Button
  disabled?: boolean;

  // Final Form Decorator
  decorators?: Decorator<object, object>[];

  // Loading Indicator
  loadingComponent?: React.ReactNode;

  // Initial Values
  initialValues?: Partial<FormValues>;

  mutators?: {
    [key: string]: Mutator<object, object>;
  };

  showDebugValues?: boolean;
};

const FinalFormWizard = <FormValues extends Record<string, any>>({
  page,
  children,
  onSubmit,
  onNext,
  onBack,
  submitText = 'Submit',
  hideControls = false,
  hideSubmitError = false,
  disabled = false,
  showDebugValues = false,
  submitRef,
  initialValues,
  onFormStateChange,
  loadingComponent,
  ...other
}: FinalFormWizardProps<FormValues>) => {
  const [values, setValues] = useState(initialValues);

  const validateChildren = React.Children.toArray(children).filter(
    (child: any) => !child.props.hide
  );
  const activePage = validateChildren[page] as any;
  const pageCount = validateChildren.length;
  const isLastPage = page === pageCount - 1;
  const isFirstPage = page === 0;

  // Need to have an active page
  if (!activePage) {
    return null;
  }

  const validatePage = async (values: any) => {
    if (activePage.props.validate) {
      return activePage.props.validate(values);
    } else {
      return {};
    }
  };

  const handleNext = (values: any) => {
    setValues(values);
    onNext(values);
  };

  const handleBack = () => {
    onBack();
  };

  const onSubmitHandler = async (values: any) => {
    if (isLastPage) {
      return onSubmit(values);
    } else {
      handleNext(values);
    }
  };

  return (
    <Form initialValues={values} onSubmit={onSubmitHandler} validate={validatePage} {...other}>
      {({ handleSubmit, submitting, submitError, values, errors }) => {
        if (submitRef) {
          submitRef.current = handleSubmit;
        }

        if (submitting) {
          if (loadingComponent) {
            return loadingComponent;
          }
          return <CircularProgress />;
        }

        return (
          <form onSubmit={handleSubmit} noValidate={true}>
            {activePage}
            {onFormStateChange && (
              <FormSpy
                subscription={{ initialValues: false, valid: true, values: true }}
                onChange={onFormStateChange}
              />
            )}
            {!hideSubmitError && submitError && (
              <Typography variant="caption" color="error">
                {submitError}
              </Typography>
            )}
            {!hideControls && (
              <FormButtons>
                {!isFirstPage && <Button onClick={handleBack}>Back</Button>}
                {isFirstPage && <span /> /* push next button to right */}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={disabled || submitting}
                >
                  {submitting ? 'Loading ...' : isLastPage ? submitText : 'Next'}
                </Button>
              </FormButtons>
            )}
            {showDebugValues && (
              <>
                <Box>
                  <pre>{JSON.stringify(values, null, 2)}</pre>
                </Box>
                <Box mt={1}>{JSON.stringify(errors)}</Box>
              </>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export interface FinalFormWizardPageProps {
  children: any;
  validate: (values: any) => Promise<Object> | Object;
  hide?: boolean;
}

FinalFormWizard.Page = ({ children, hide = false }: FinalFormWizardPageProps) => {
  if (!hide) {
    return children;
  }
  return null;
};

export default FinalFormWizard;
